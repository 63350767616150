import ButtonNavigation from "@/components/ButtonNavigation"
import { toReactive } from "@vueuse/core"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import EditSignature from "../standard/components/EditSignature"
import EditEmployment from "../standard/employment/EditEmployment"

const EmployerDetailsNeeded: ReactiveComponent = () => {
	const records = toReactive(computed(() => App.CustomerClaim.EmploymentRecords))
	return () => (
		<>
			<div class="col-12 mb-4 text-center">
				<h3 class="bold"><i class="fa fa-info"></i> Employer Details Required</h3>
			</div>
			<p class="alert alert-warning">
				Because you have not been able to provide PAYE details for one or more of your employers you will need to sign authority for HMRC to release these details to us. Please can you sign below to specifically authorise this.
			</p>
			<br />
			<label class="custom-control custom-control-inline custom-checkbox">
				<input
					type="checkbox"
					class="custom-control-input required"
					v-model={App.CustomerClaim.ConsentContactEmployers}
					name="terms"
					id="inp_SAR_auth"
					data-validation="You must agree to the terms before you proceed."
				/>

				<span class="custom-control-label bold">
					I authorise Online Tax Rebates Ltd / Central Legal Services to receive the following information.
				</span>
			</label>
			<p>I authorise the request of the following information, on my behalf from HMRC, such that Online Tax Rebates Ltd / Central Legal Services can complete and submit any tax refund claim I have already signed by the addition of the relevant PAYE references. I also understand that OTR/CLS may use this information in order to make a basic assessment of the likely hood of the claim to produce a refund which may determine whether any expense claim is submitted.</p>
			<ul>
				<li>A list of all the PAYE employers HMRC hold on me on their database in the last 6 years, including the current tax year.</li>
				<li>For each employer, the PAYE reference number, full address of the employer with postcode and payroll number to the full extent it is available.</li>
				<li>The salary and tax paid for each employment.</li>
				<li>For each employment details of any P11D benefits in kind HMRC has on record</li>
				<li>For each employment details of any flat rate expenses, subscriptions to professional bodies, and other expenses.</li>
				<li>If a self assessment record exists for me.</li>
				<li>If I am considered to be a taxpayer in Scotland, Wales, England or NI</li>
				<li>The name and address of any party with 64-8 agent authority to access my taxpayer records.</li>
				<li>If a marriage allowance transfer has been made either to or from my taxpayer record</li>
			</ul>
			<p>
				For the avoidance of doubt this request does not constitute any request or authorisation to act as
				agent in the name of OTR or CLS or any other party.
			</p>
			<EditSignature setSignatureSVG={(svg) => (App.CustomerClaim.SARSignatureSVG = svg)} />
			<ButtonNavigation hideBack nextText="Submit details" />
		</>
	)
}

export default defineComponent(EmployerDetailsNeeded)
