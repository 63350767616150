import { defineComponent, type ReactiveComponent } from "vue-utils"
import EditSignature from "./components/EditSignature"

const SignatureDetails: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 my-2">
				<h2 class="bold">Letter of Assignment</h2>
				<br />
				<label class="custom-control custom-control-inline custom-checkbox">
					<input
						type="checkbox"
						class="custom-control-input required"
						v-model={App.CustomerClaim.AcceptedTerms}
						name="terms"
						id="inp_accept_terms"
						data-validation="You must read and agree to the terms & conditions before you can proceed."
					/>

					<span class="custom-control-label">
						I agree to Online Tax Rebate's{" "}
						<a href="/terms" target="_blank">
							terms and conditions
						</a>
						.{" "}
						<a href="/terms" style="text-decoration: underline !important;" target="_blank">
							Click here to see the full terms and conditions
						</a>
						.
					</span>
				</label>
			</div>

			<div class="col-12 my-2 text-center">
				<p>
					<small>
						I unconditionally assign any overpayment for all of the last 4 tax years to Online Tax Rebates Limited.
					</small>
				</p>
			</div>

			<EditSignature setSignatureSVG={(svg) => (App.CustomerClaim.SignatureSVG = svg)} />
		</>
	)
}

export default defineComponent(SignatureDetails)
